import {
  Component,
  OnInit,
  HostListener,
  ElementRef
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import _ from 'lodash';

import { IAlert } from './models';
import { TSystem } from './types';
import { AlertService } from './services/alert.service';
import { ReduxService } from './redux/redux.service';
import { SubscriptionService } from './services/subscription.service';
import { SharedService } from './services/shared.service';
import { SystemService } from './services/system.service';
import { ThemeService } from './services/theme.service';
import { SetupService } from './services/setup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  system: TSystem;
  isLogin: boolean;
  isHelpDocument: boolean; // 当前处于帮助文档页

  setupIsDone: boolean;

  @HostListener('click', ['$event.target'])
  onClick() {
    this.subscriptionService.publishHideAddChildPlanDropDown();
  }

  isLoading = false;
  timeStamp = '';
  setupSubscription: Subscription;
  constructor(
    private systemService: SystemService,
    private alertService: AlertService,
    private router: Router,
    private reduxService: ReduxService,
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService,
    private elementRef: ElementRef,
    private themeService: ThemeService,
    private setupService: SetupService
  ) { }

  ngOnInit() {
    this.getSystemSync();
    this.initTitle();
    // this.verifyIE11();
    // this.googleBrowserPromotion();
    this.verifyMainRoute();
    this.subscriptionService.subscribeLoading().subscribe(callback => {
      this.loading(callback);
    });
    this.subscriptionService.subscribePureLoading().subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.getSetup();
    this.setupSubscription = this.subscriptionService.subscribeSetup().subscribe(() => {
      this.getSetup();
    });
  }

  initTitle() {
    this.systemService.initTitle();
  }

  getSystemSync() {
    this.system = this.systemService.getSystemSync();
  }

  verifyIE11() {
    const userAgent = window.navigator.userAgent;
    if (!(-1 !== userAgent.indexOf('NET') && -1 !== userAgent.indexOf('rv:'))) {
      return;
    }
    const strStart = userAgent.indexOf('rv:');
    const strStop = userAgent.indexOf(')');
    const version = userAgent.substring(strStart + 3, strStop - 1);
    const mainVersion = parseInt(version, 10);
    console.log('version:', mainVersion);
    if (!(mainVersion < 11)) {
      return;
    }
    const alert: IAlert = {
      type: 'warning',
      title: '请升级至IE11版本，体验最好！'
    };
    this.alertService.addAlert(alert);
  }

  // // 谷歌浏览器宣贯
  // googleBrowserPromotion() {
  //   const alert: IAlert = {
  //     type: 'warning',
  //     title: 'IE性能较差，推荐使用谷歌浏览器体验极佳。',
  //     link: { name: '点击下载谷歌浏览器', url: 'https://pcms.foton.com.cn/api/files/docs_1/tools/ChromeStandaloneSetup64.exe' },
  //     timeout: 60000
  //   };
  //   this.alertService.addAlert(alert);
  // }

  // 获取主路由地址
  verifyMainRoute() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.url;
      // 项目中心
      const isProjectCenter = -1 !== url.indexOf('/project-center');
      this.reduxService.setCommonIsProjectCenter(isProjectCenter);
      // login
      this.isLogin = -1 !== url.indexOf('/login');
      this.isHelpDocument = -1 !== url.indexOf('/help-document');
    });
  }

  // 显示加载中
  private loading(callback?: () => void) {
    this.isLoading = true;
    const _self = this;
    // 触发变更检测，显示loading图标
    setTimeout(() => {
      if (callback) {
        callback();
      }
      _self.checkUIRenderCompleted();
    });
  }

  // 检查UI是否渲染完成
  private checkUIRenderCompleted() {
    this.timeStamp = new Date().getTime() + '';
    const _self = this;
    let timer = null;
    function checkUIRenderCompleted() {
      const timeStampEle = _self.elementRef.nativeElement.querySelector('div#timeStamp');
      if (timeStampEle && timeStampEle.innerHTML === _self.timeStamp) {
        _self.isLoading = false;
        if (!timer) {
          clearTimeout(timer);
        }
      } else {
        timer = setTimeout(checkUIRenderCompleted, 100);
      }
    }
    checkUIRenderCompleted();
  }

  switchTheme() {
    this.themeService.setTheme();
  }

  showSideMenu() {
    // 在 禅道客户端 中不显示左侧菜单栏
    const isOpenInZentao = navigator.userAgent.toLowerCase().indexOf("xuanxuan") > -1;
    return !isOpenInZentao;
  }

  // 是否初始化完成
  getSetup() {
    this.setupService.getSetup().subscribe(result => {
      this.setupIsDone = result.is_done;
    })
  }

  ngOnDestroy() {
    this.setupSubscription.unsubscribe();
  }
}

