
export const ROOT_PLAN_TEMPLATE_PARENT_ID = undefined;  // 一级计划模板的parent_id
export const ROOT_ENGIN_FIELD_PARENT_KEY = null; // 一级所属业务的parent_key
export const ROOT_ORGANIZATION_TREE_ITEM_PARENT_CODE = '00000000000000000000';  // 一级组织树节点的parent_code
export const ROOT_PARENT_ID = undefined;  // 一级（计划等）的parent_id
export const ORGANIZATION_ROOT_PARENT_ID = null;  // 一级组织的parent_id
export const PLAN_NEW_ID = '0';  // 新建计划的id
export const PLAN_TEMPLATE_NEW_ID = 0;  // 新建计划模板的id
export const DELIVERABLE_TEMPLATE_NEW_ID = 0;  // 新建交付物模板的id
export const MILESTONE_TEMPLATE_NEW_KEY = '0';  // 新建节点模板的key

export const PAGE_SIZE = 10;    // 分页大小
export const PAGE_LINK_LIMIT = 1;    // 最多显示的页码数量
export const PAGE_MAX_LIMIT = 99999;    // 滚动加载（不分页）时查询的最大条数
export const UNCHECKED_KEY = '';    // 筛选条件未选中时的key值
export const FILE_URL_PREFIX = ''; // 文件地址前缀（附件、交付物等）
export const FILE_SIZE_LIMIT = 50;   // 文件大小限制（单位 MB） // bytes . 
export const NUMBER_PAD_LENGTH = 5;  // 数字补全长度（1.1->00001.00001）
export const GET_TODO_RECEIVE_DELAY = 5000;   // 5秒 // 路由变化后，延迟5秒获取待接收列表
export const COUNT_THROTTLE_TIME = 60000;    // 60秒  // 获取count的节流时间
export const GROUP_DIVISION = '31000000';   // 集团事业部编码
export const IN_PROGRESS_PROJECT_VERSION = 0;   // 正在进行中的项目版本（还未版本化）
export const LOW_LEVEL_SCORE = 0.8; // 主观评价β打分时低级别分数为<0.8（打分遵循262原则）
export const NORMAL_LEVEL_SCORE = 1.0; // 主观评价β打分时正常级别分数为1.0
export const HIGH_LEVEL_SCORE = 1.2; // 主观评价β打分时高级别分数为1.2
export const PRODUCT_ENGINEERING_ENGIN_FIELD_KEY = 'PD'; // 所属业务为“产品工程”的engin_field_key
export const NONE_MANAGER_USER_ID = 'null'; // 没有直管领导，manager_user_id为'null'
export const PERFORMANCE_APPROVAL_PERSON_NEW_ID = 0; // 绩效报批新增人员id
export const PROJECT_INFO_DROPDOWN_STEP_NUMBER = 1; // 项目信息下拉值step值
export const ADMIN_SETTINGS_STEP_NUMBER = 2; // 管理员step值
export const MILESTONE_TEMPLATE_STEP_NUMBER = 3; // 节点模板step值
export const ENGIN_FIELD_STEP_NUMBER = 4; // 所属业务step值


// #region 组织
export const ORGANIZATION_TEMPLATE_NEW_ID = 0;  // 新建组织模板的id
export const ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH = '/';    // 组织树根节点（项目经理）的 parent_node_path
export const ORGANIZATION_CHART_ROOT_NAME = '项目经理';    // 组织树根节点的名称（已废弃，实际使用时根据parent_node_path为'/'来判断是否是根节点）
export const ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR = '@';    // 组织树上非主分支的节点的 parent_node_path 的分隔符
export const ORGANIZATION_CHART_FIRST_SCREEN_COLS = 10; // 组织树首屏加载的列数
export const ORGANIZATION_CHART_ITEM_STYLE = {
  WIDTH: 120,    // 节点宽度
  SPACER: 32, // 节点间距
  LINE_WIDTH: 4   // 连接线宽度
};
export const ORGANIZATION_CHART_PROJECT_MANAGEMENT_ADMIN = {    // 组织树“项目管理员”角色
  name: '项目管理员',
  parent_node_path: '/项目经理/',
};
export const ORGANIZATION_CHART_MANAGEMENT_ADMIN = { // 组织树“管理经理”角色
  name: '管理经理',
  parent_node_path: '/项目经理/产品工程经理/'
};
export const ORGANIZATION_CHART_PRODUCT_ENGINEERING_ADMIN_NODE_PATH = '/项目经理/产品工程经理/'; // “产品工程经理”的节点路径
export { DEFAULT_ORGANIZATION_CHART_ITEMS } from './DEFAULT_ORGANIZATION_CHART_ITEMS';  // 默认组织架构
export { DEFAULT_ROLE_NAMES } from './DEFAULT_ROLE_NAMES'; // 角色名称
export const I_PROJECT_DEFAULT_ORG_TEMPLATE_CATEGORY_KEY = null; // 一类项目，默认组织模板细分类key为null
export const PERSON_MAX_LIMIT = 100; // 组织节点成员最多人数默认为100
export const ORGANIZATION_PERMISSION_LEVEL_3 = 4; // 三级组织变更权限中有4个'/'(eg: '/项目经理/产品工程经理/PMT经理/')
// #endregion

// #region 工作流
export const WORKFLOW_STEP_SHOW_PERSONS_MAX_LIMIT = 5;   // 工作节点显示的审批人数限制，超出则显示“折叠/展开”按钮
export const WORKFLOW_STEP_APPROVER_NAME = '审批人'; // 工时下发审批流程，“审批人”选择人员范围不同
export const WORKFLOW_COUNTERSIGN_STEP = '会签'; // 工作流“会签”节点
export const WORKFLOW_COUNTERSIGN_STEP_1 = '会签1'; // 工作流“会签1”节点
export const FRONT_STEP_NAME = '批准'; // “中心主任”节点放置到“批准”节点后
export const BEHIND_STEP_NAME = '中心主任'; // “批准”节点放置到“中心主任”节点前
export const CENTER_DIRECTOR_STEP_NAME = '中心主任'; // 中心主任节点
export const APPROVAL_STEP_NAME = '批准'; // 批准节点（三级组织变更时，中心主任节点与批准节点不可同时存在）
// #endregion

// #region 需求
export const REQUIREMENT_BILL_NEW_ID = '0'; // 新建需求单的id
export const REQUIREMENT_TYPE_CUSTOMER_VOICE = {  // 需求类型-客户声音
  key: '02',
  name: '客户声音'
};
export const REQUIREMENT_MANAGEMENT_ENGINEER_ROLE_NAME = '需求管理工程师' // 需求团队角色-'需求管理工程师'名称
export const DELAY_REFRESH_TIME = 1000; // 延迟刷新页面获取列表
// #endregion

